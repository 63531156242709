

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  margin: 0;
  padding: 0;
}

body {
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  /*font-family:"16 px apercu",sans-serif; */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .adminLeft {
    display: none;
  }

  .deskTable {
    display: none !important;
  }

  .d-flex {
    flex-direction: column;
  }

  .modelbutton {
    flex-direction: column-reverse;
  }

  .homevasthubtn {
    flex-direction: column-reverse !important;
  }

  .homevasthubutton {
    width: 100% !important;
  }
}

@media only screen and (min-width: 768px) {
  .adminTabMobile {
    display: none;
  }

  .mobileCard {
    display: none;
  }

  .modelbutton {
    flex-direction: row;
  }

  .homevasthubtn {
    display: flex !important;
    justify-content: space-evenly !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.row2 {
  height: 100%;
  width: 100%;
  align-content: center;
  background-color: #ffbb33;
  position: absolute;
}

.signuprow2 {
  width: 100%;
  height: 91vh;
  overflow: scroll;
  /* align-content: center; */
  background-color: #ffbb33;
  position: absolute;
}

@media (min-width: 289px) and (max-width: 391px) {
  .signuprow2 {
    height: 79vh;
  }
}
@media (min-width: 392px) and (max-width: 1034px) {
  .signuprow2 {
    height: 87vh;
  }
}
@media (min-width: 1035px) and (max-width: 1332px) {
  .signuprow2 {
    height: 90vh;
  }
}

@media (min-width: 100px) and (max-width: 1100px) {
  .row2 {
    height: auto !important;
  }
}

.row3 {
  height: 100%;
  width: 100%;
  /* align-content:  center; */
  position: absolute;
}

.row1 {
  height: auto;
  width: 100%;
  align-content: center;
  background-color: white;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border: 1px solid #d5d5d5;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

.jothi-button {
  cursor: pointer;
}

.jothi-body {
  filter: grayscale(100%);
  color: #1c1d1f;
  position: relative;
  text-align: center;
  align-self: center;
  align-items: center;
}

.jothi-body:hover {
  color: #fec009;
  filter: hue-rotate(354deg);
  transition: 300ms;
}

.jothi-title {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin-left: 1rem;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

/* @media only screen and (max-width: 992px) { */
.bottom-nav {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
}

.bn-tab {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hoverText:hover {
  background-color: #fde3b0;
  text-shadow: 2px 2px 5px rgb(29, 29, 28);
}

.Loginrow {
  height: 100%;
  width: 100%;
  align-content: center;
  background-color: #ffbb33;
  position: absolute;
}

.bottom-nav {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  border-top: 1px solid rgb(230, 230, 230);
}

.bn-tab {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accRow1 {
  height: auto;
  background-color: #ffffff;
  justify-content: space-evenly;
  padding: 2rem;
}

.accCol1 {
  padding: 2rem;
}

.accCol2 {
  padding: 2rem;
}

.service-card {
  width: auto;
  margin: 3rem;
  cursor: pointer;
  border-width: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s;
}

.service-card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.homeFeaturesTitle {
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #f7f9fa;
  color: #1c1d1f;
  font-size: 2rem;
  text-align: center;
  /* text-shadow: 2px 2px 5px rgb(0, 0, 0); */
}

.review-progressbar {
  border-radius: 2rem;
}

.circular-progressbar {
  display: flex;
  margin: 2rem;
  width: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.star-circle {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
}

.featureContent {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem;
  display: flex;
  justify-content: space-evenly;
}

.color-nav {
  background-color: #ffbb33;
  color: rgb(235, 10, 10);
}

.featuresCard {
  width: 18rem;
  margin: 2rem;
  border-width: 3px;
}

.reviewCol1 {
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  font-size: 2rem;
  text-shadow: 2px 2px 3px rgb(175, 173, 173);
}

.userReviewCarousel {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  background-color: #ffbb33;
  padding: 4rem;
  display: flex;
  justify-content: space-evenly;
}

@media (min-width: 100px) and (max-width: 500px) {
  .hometestimonials {
    padding: 2rem !important;
  }

  .consultantbtn {
    flex-direction: row;
  }

  .admindate_history {
    flex-direction: column;
  }

  .chat_accordian .accordion-body {
    padding: 0 !important;
  }
}

.hometestimonials {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  background-color: #ffbb33;
  padding: 4rem;
  display: flex;
  justify-content: space-evenly;
}

.testimonial-card {
  width: auto !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.on {
  color: #1264a3;
}

.off {
  color: #ccc;
}

.moreReviewBtn {
  width: 15rem;
  margin-top: 7px;
}

/* .carouselReview{


justify-content: center;
height: fit-content;
 align-content: center;
  align-items: center;

 

} */
.plot-submit {
  width: auto;
}

.homeNav {
  background-color: #fbfdfd;
}

.homeUserReview {
  padding: 4%;
  background-color: #1c1d1f;
}

.homeNav {
  background-color: #fbfdfd;
}

.homeUserReview {
  padding: 4%;
  background-color: #1c1d1f;
}

.reviewCard {
  width: auto;
  border-width: 3px;
  margin: 0.5rem;
}

.offerCard {
  width: auto;
  border-width: 3px solid #ccc;
  margin: 0.5rem;
  background-color: #ffffff;
  color: #52b5eb;
}

.offerCard:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.userInfo {
  display: flex;
  justify-content: space-evenly;
  font-size: 24px;
}

.home-video {
  background-color: #ffbb33;
  text-align: center;
}

.videoStream {
  width: 100% !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.topCarousel {
  background-color: rgb(255, 255, 255);
}

.review-app-info {
  margin: 2rem;
}

.review-app-title {
  font-size: 2rem;
  font-weight: bold;
  color: #ffbb33;
  text-align: center;
  margin: 1rem;
}

.review-app-profile {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: center;
}

.review-profile-detail {
  font-size: 18px;
  flex-direction: column;
  justify-content: center;
}

.homeFooterTitle {
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #20201f;
  color: azure;
  font-size: 2rem;
  text-align: center;
  text-shadow: 2px 2px 5px #f3d992;
}

.footer-headerline {
  color: #f0df20;
}

.footerRow {
  background-color: #f9f9f9;
  justify-content: space-between;
}

.footerRow:after {
  content: "";
  display: table;
  clear: both;
}

.footerColumn {
  float: left;
  width: 33%;
  padding: 0 10px;
  margin-top: 25px;
}

@media screen and (max-width: 600px) {
  .footerColumn {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.footerCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  color: #ffffff;
  border-color: #ffffff;
  background-color: #534e4e;
}

.createSlot-mins {
  width: 20rem;
  justify-content: space-evenly;
}

.createSlot-sTime {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-evenly;
}

.createSlot-fromDate {
  display: flex;
  justify-content: flex-start;
}

.review-list ul li .left span {
  width: 32px;
  height: 32px;
  display: inline-block;
}

.review-list ul li .left {
  flex: none;
  max-width: none;
  margin: 0 10px 0 0;
}

.review-list ul li .left span img {
  border-radius: 50%;
}

.review-list ul li .right h4 {
  font-size: 16px;
  margin: 0;
  display: flex;
}

.review-list ul li .right h4 .gig-rating {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #ffbf00;
}

.review-list ul li .right h4 .gig-rating svg {
  margin: 0 4px 0 0px;
}

.country .country-flag {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  margin: 0 7px 0 0px;
  border: 1px solid #fff;
  border-radius: 50px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.country .country-name {
  color: #95979d;
  font-size: 13px;
  font-weight: 600;
}

.review-list ul li {
  border-bottom: 1px solid #dadbdd;
  padding: 0 0 30px;
  margin: 0 0 30px;
}

.review-list ul li .right {
  flex: auto;
}

.review-list ul li .review-description {
  margin: 20px 0 0;
}

.review-list ul li .review-description p {
  font-size: 14px;
  margin: 0;
}

.review-list ul li .publish {
  font-size: 13px;
  color: #95979d;
}

.review-section h4 {
  font-size: 20px;
  color: #222325;
  font-weight: 700;
}

.review-section .stars-counters tr .stars-filter.fit-button {
  padding: 6px;
  border: none;
  color: #4a73e8;
  text-align: left;
}

.review-section .fit-progressbar-bar .fit-progressbar-background {
  position: relative;
  height: 8px;
  background: #efeff0;
  flex-grow: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 999px;
}

.review-section .stars-counters tr .star-progress-bar .progress-fill {
  background-color: #ffb33e;
}

.review-section .fit-progressbar-bar .progress-fill {
  background: #2cdd9b;
  background-color: rgb(29, 191, 115);
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  border-radius: 999px;
}

.review-section .fit-progressbar-bar {
  display: flex;
  align-items: center;
}

.review-section .stars-counters td {
  white-space: nowrap;
}

.review-section .stars-counters tr .progress-bar-container {
  width: 100%;
  padding: 0 10px 0 6px;
  margin: auto;
}

.ranking h6 {
  font-weight: 600;
  padding-bottom: 16px;
}

.ranking li {
  display: flex;
  justify-content: space-between;
  color: #95979d;
  padding-bottom: 8px;
}

.review-section .stars-counters td.star-num {
  color: #4a73e8;
}

.ranking li > span {
  color: #62646a;
  white-space: nowrap;
  margin-left: 12px;
}

.review-section {
  border-bottom: 1px solid #dadbdd;
  padding-bottom: 24px;
  margin-bottom: 34px;
  padding-top: 64px;
}

.review-section select,
.review-section .select2-container {
  width: 188px !important;
  border-radius: 3px;
}

ul,
ul li {
  list-style: none;
  margin: 0px;
}

.helpful-thumbs,
.helpful-thumb {
  display: flex;
  align-items: center;
  font-weight: 700;
}

/* slider css */
.carousel {
  overflow: auto;
}

.col-item {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background: #fff;
}

.col-item .photo img {
  margin: 0 auto;
  width: 100%;
}

.col-item .info {
  padding: 10px;
  border-radius: 0 0 5px 5px;
  margin-top: 1px;
}

.col-item:hover .info {
  background-color: #f5f5dc;
}

.col-item .price {
  /*width: 50%;*/
  float: left;
  margin-top: 5px;
}

.col-item .price h5 {
  line-height: 20px;
  margin: 0;
}

.price-text-color {
  color: #219fd1;
}

.col-item .info .rating {
  color: #777;
}

.col-item .rating {
  /*width: 50%;*/
  float: left;
  font-size: 17px;
  text-align: right;
  line-height: 52px;
  margin-bottom: 10px;
  height: 52px;
}

.col-item .separator {
  border-top: 1px solid #e1e1e1;
}

.clear-left {
  clear: left;
}

.col-item .separator p {
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 10px;
  text-align: center;
}

.col-item .separator p i {
  margin-right: 5px;
}

.col-item .btn-add {
  width: 50%;
  float: left;
}

.col-item .btn-add {
  border-right: 1px solid #e1e1e1;
}

.col-item .btn-details {
  width: 50%;
  float: left;
  padding-left: 10px;
}

.controls {
  margin-top: 20px;
}

[data-slide="prev"] {
  margin-right: 10px;
}

/* swiper styles */
.swiper {
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.imgbutton {
  position: relative;
}

.image1 {
  cursor: pointer;
  width: 100%;
}

.label1 {
  margin-left: -60%;
  margin-top: 30%;
  cursor: pointer;
}

/* for MyAccordian */
.accordian_base {
  /* border: 2px solid #ffb33e; */
  border: 1px solid #ffb33e !important;
}

.accordian_label {
  color: #ffb33e;
  text-transform: uppercase;
  font-weight: normal;
  /* font-style: italic; */
  font-family: Roboto;
}

.accordian_arrow {
  color: #ffb33e;
  text-transform: uppercase;
  font-weight: bold;
}

/* for MyAccordian - End */

/* Add service */
.addservice_table {
  width: 100%;
  border-collapse: collapse;
}

.addservice_table th {
  background: #ffb33e;
  color: white;
  font-weight: bold;
}

.addservice_table td,
.addservice_table th {
  padding: 6px;
  text-align: left;
}

@media only screen and (max-width: 760px) {
  /* Force table to not be like tables anymore */
  .addservice_table table,
  .addservice_table thead,
  .addservice_table tbody,
  .addservice_table th,
  .addservice_table td,
  .addservice_table tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .addservice_table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .addservice_table tr {
    border: 1px solid #ffb33e;
  }

  .addservice_table tr:nth-of-type(odd) {
    background-color: #ffb33e;
  }

  .addservice_table td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #bbb;
    position: relative;
    padding-left: 50%;
  }

  .addservice_table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .addservice_accordian td:nth-of-type(1):before {
    content: "Service Name";
  }

  .addservice_accordian td:nth-of-type(2):before {
    content: "Count";
  }

  .addservice_accordian td:nth-of-type(3):before {
    content: "Duration";
  }

  .addservice_accordian td:nth-of-type(4):before {
    content: "Payment";
  }

  .addservice_accordian td:nth-of-type(5):before {
    content: "Base Price";
  }

  .addservice_accordian td:nth-of-type(6):before {
    content: "Discount (%)";
  }

  .addservice_accordian td:nth-of-type(7):before {
    content: "Discount Price";
  }

  .addservice_accordian td:nth-of-type(8):before {
    content: "Actual Price";
  }
}

.modelbtn {
  display: flex !important;
  justify-content: center !important;
}

/* Add service End*/
/*  for  tables */
.reporttable {
  width: 100%;
  border-collapse: collapse;
}

.reporttable th {
  background: #ffb33e;
  color: white;
  font-weight: bold;
}

.reporttable td,
.reporttable th {
  padding: 6px;
  /* border: 1px solid #bbb;  */
  text-align: left;
}

#elementtable,
#elementtable tr,
#elementtable td {
  width: 100%;
}

#elementtable tr {
  border-bottom: 1px solid #ffb33e;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  .reporttable table,
  .reporttable thead,
  .reporttable tbody,
  .reporttable th,
  .reporttable td,
  .reporttable tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .reporttable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .reporttable tr {
    border: 1px solid #ffb33e;
  }

  .reporttable tr:nth-of-type(odd) {
    background-color: #ffb33e;
  }

  /* .reporttable td:nth-of-type(odd) { 
    background: #ccc; 
  }
  .reporttable td:nth-of-type(even) { 
    background: rgb(255, 255, 255);  
  } */
  .reporttable td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #bbb;
    position: relative;
    padding-left: 50%;
  }

  .reporttable td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* background-color: white; */
  }

  /*
    Label the data
    */
  #maindoortable td:nth-of-type(1):before {
    content: "Building Width";
  }

  #maindoortable td:nth-of-type(2):before {
    content: "Main Facing";
  }

  #maindoortable td:nth-of-type(3):before {
    content: "Main door placement";
  }

  #elementtable td:nth-of-type(1):before {
    content: "Element Name";
  }

  #elementtable td:nth-of-type(2):before {
    content: "Room Width";
  }

  #elementtable td:nth-of-type(3):before {
    content: "Width Status";
  }

  #elementtable td:nth-of-type(4):before {
    content: "Direction";
  }

  #elementtable td:nth-of-type(5):before {
    content: "Direction Status";
  }

  #misctable td:nth-of-type(1):before {
    content: "Name";
  }

  #misctable td:nth-of-type(2):before {
    content: "Direction";
  }

  #misctable td:nth-of-type(3):before {
    content: "Result";
  }

  #windowtable td:nth-of-type(1):before {
    content: "Name";
  }

  #windowtable td:nth-of-type(2):before {
    content: "Facing";
  }

  #leveltable td:nth-of-type(1):before {
    content: "Levelling";
  }

  #leveltable td:nth-of-type(2):before {
    content: "SW Vs NE";
  }

  #leveltable td:nth-of-type(3):before {
    content: "W Vs E";
  }

  #leveltable td:nth-of-type(4):before {
    content: "S Vs N";
  }

  .maindoor_accordian td:nth-of-type(1):before {
    content: "Karpam";
  }

  .maindoor_accordian td:nth-of-type(2):before {
    content: "Profit-Loss";
  }

  .maindoor_accordian td:nth-of-type(3):before {
    content: "Vayuthu";
  }

  .maindoor_accordian td:nth-of-type(4):before {
    content: "Yoni";
  }
}

/*  for  tables - end*/

/* for react bootstrap accordian */
.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #ffb33e;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-item {
  border: 1px solid #ffb33e;
}

.accordion-button {
  width: 98.5%;
}

/* for react bootstrap accordian -End */
.professionalCard:hover {
  transform: scale(1, 1);
  box-shadow: 2px 1px 2px 1px #ffb33e;
}

.professionalCard:hover .profProfile {
  box-shadow: 2px 2px 10px #ffb33e inset;
}

.nav-link {
  font-weight: bold !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ffb33e !important;
}

Animation call effects * {
  margin: 0;
  padding: 0;
}

.hlo {
  margin-bottom: 460px;
  position: absolute;
  color: white;
}

.contnent {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #2f3640;
}

i {
  color: white;
}

.call_proceed {
  height: 100px;
  width: 100px;
  background-color: #44bd32;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.call_proceed:before {
  content: "";
  position: absolute;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  border: 1px solid #44bd32;
  animation: animate 1.5s linear infinite;
}

.call_proceed:after {
  content: "";
  position: absolute;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  border: 2px solid #44bd32;
  animation: animate 1.5s linear infinite;
  animation-delay: 0.4s;
}

.call_failed {
  height: 100px;
  width: 100px;
  background-color: #ee1111;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.call_failed:before {
  content: "";
  position: absolute;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  border: 1px solid #ee1111;
  animation: animate 1.5s linear infinite;
}

.call_failed:after {
  content: "";
  position: absolute;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  border: 2px solid #ee1111;
  animation: animate 1.5s linear infinite;
  animation-delay: 0.4s;
}

@keyframes animate {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

/* @media (min-width: 100px) and (max-width: 500px) {
  .offerDetail_Input {
    padding: 2rem !important;
  }
} */
.feildset {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

@media (min-width: 100px) and (max-width: 500px) {
  .feildset {
    display: block;
  }
}

.form_feild {
  border-color: #ffb33e !important;
  font-size: 1rem !important;
}

.form_feild:focus {
  border-color: #ffb33e !important;
  box-shadow: 0 0 0 0.1rem #ffb33e !important;
}

.form_feild option {
  background-color: #f3dfa2 !important;
}

.accordian_style {
  border-color: #ffb33e !important;
}

.accordian_style Accordion.Header {
  background-color: #ffbb33 !important;
}

.accordian_style .accordion-button:not(.collapsed) {
  background-color: #ffbb33 !important;
}

.accordian_style .accordion-button:focus {
  box-shadow: 0 0 0 0.1rem #ffb33e !important;
}

.dropdown .dropdown-menu.show {
  border: 1px solid #ffb33e;
}

/* forgrt form start */
.country_feild {
  max-width: 25%;
}

.select_feild {
  max-width: 80%;
}
.forget_btn {
  width: 200px;
}

@media (min-width: 100px) and (max-width: 500px) {
  .forget_form {
    display: block !important;
  }

  .forget_feild {
    width: 100% !important;
  }

  .forget_btn {
    width: 100%;
    margin-top: 10px !important;
  }

  .select_feild {
    max-width: 70%;
  }

  .countrywidth {
    max-width: 35% !important;
  }

  .homephonenumberview {
    flex-direction: column;
    display: flex;
  }

  .btnfullwidth {
    width: 100%;
  }

  .columreversebtn {
    flex-direction: column-reverse;
  }
}

@media (min-width: 500px) and (max-width: 2560px) {
  .forget_feild {
    width: 100% !important;
  }
}

.forget_form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}

/* forgrt form end */
/* Mui DataGrid table start */
.deskTable .MuiDataGrid-row {
  border: 1px solid #ffb33e !important;
}

.deskTable .css-1iyq7zh-MuiDataGrid-columnHeaders {
  background-color: #ffbb33 !important;
}

/* Mui DataGrid table end */

.prof_user {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.countrywidth {
  max-width: 22% !important;
}

@media (min-width: 100px) and (max-width: 800px) {
  .prof_user {
    display: block;
  }
}

.admin_user {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.home_rec_modal {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

@media (min-width: 100px) and (max-width: 500px) {
  .home_rec_modal {
    display: block !important;
  }
}

@media (min-width: 100px) and (max-width: 400px) {
  .admin_user {
    display: block;
  }
}

.all_btn {
  border-color: #ffb33e !important;
  background-color: #ffffff !important;
  font-weight: bold !important;
  color: black !important;
}

.all_btn:hover {
  border-color: #ffb33e !important;
  background-color: #ffbb33 !important;
}

.all_btn1 {
  border-color: #ffb33e !important;
  background-color: #ffbb33 !important;
  font-weight: bold !important;
  color: black !important;
}

.all_btn1:hover {
  border-color: #ffb33e !important;
  background-color: #ffffff !important;
}

.all_border {
  border-color: #ffb33e !important;
}

.all_background {
  background-color: #ffbb33 !important;
}

.slot_check .form-check-input {
  border: 1px solid #ffb33e !important;
}

.slot_check .form-check-input:checked {
  background-color: #ffbb33 !important;
  border-color: #ffb33e !important;
}

.slot_check .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem #ffb33e !important;
}

.scroll_bar {
  overflow-y: scroll;
}

.scroll_bar::-webkit-scrollbar {
  width: 0px;
}

.scroll_bar:hover::-webkit-scrollbar {
  width: 2px !important;
}

.add_cat .optionListContainer {
  position: unset !important;
}

@media (min-width: 100px) and (max-width: 425px) {
  .add_cat_btn {
    flex-direction: column-reverse !important;
  }
}

.nav_link {
  text-decoration: none;
  color: #000000a6;
  padding: 8px;
  font-weight: bolder;
}

.nav_link:hover {
  color: #ffbb33;
}

.nav_link_home {
  text-decoration: none;
  color: #000000a6;
  padding: 8px;
  font-weight: bolder;
}

.nav_link_home:hover {
  color: #ffffff;
}

.nav_linkhead {
  text-decoration: none;
  color: #000000a6;
  padding: 8px;
  font-weight: bolder;
}

.nav_linkhead:hover {
  color: #ffffff;
}

.nav_header .navbar-nav .nav-link.active,
.nav_header .navbar-nav .nav-link.show,
.nav_header .navbar-nav .nav-link:hover {
  color: #ffffff;
}

/* .nav_dpd_title:hover {
  color: #ffffff;
} */

/* special offer start */
/* .special_card {
  margin-right: 10px;
  height: 230px !important;
  --bs-card-bg: #fff0 !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
} */

.special_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 50%;
}

.special_content {
  display: flex;
  height: 130px;
  flex-direction: column;
  align-items: flex-start;
}

.special_btn {
  border-color: #ffb33e !important;
  background-color: #ffbb33 !important;
  font-weight: bold !important;
  color: white !important;
}

.special_btn:hover {
  border-color: #ffb33e !important;
  background-color: #ffffff !important;
  color: black !important;
}

.special_offername {
  font-weight: bold;
  font-size: x-large;
  top: 0;
  background-color: transparent;
  position: -webkit-sticky !important;
  position: sticky !important;
}

.special_price {
  font-weight: bold;
  font-size: larger;
}

.special_service {
  font-weight: bold;
  font-size: medium;
}

.special_des {
  font-weight: bold;
  font-size: x-small;
  text-align: left;
}
.special_offer_card {
  background-color: #f4dec2 !important;
}
.special_offer_row {
  height: 200px;
  padding: 10px;
  align-items: center;
}

.special_offer_img {
  height: 170px !important;
  object-fit: contain;
  width: 100%;
  border-radius: 5px;
}

@media (min-width: 100px) and (max-width: 330px) {
  .special_offer_row {
    display: flex !important;
    flex-direction: column;
    height: 310px !important;
  }
  .special_offer_img {
    height: 129px !important;
  }
  /* .special_card {
    margin-right: 0px !important;
    height: 140px !important;
  } */

  .special_body {
    margin-left: 40% !important;
  }

  .special_content {
    height: 110px !important;
  }

  .special_offername {
    font-size: medium !important;
  }

  .special_price {
    font-size: medium !important;
  }

  .special_service {
    font-size: small !important;
  }

  .special_des {
    font-size: small !important;
  }

  .countrywidth {
    max-width: 35% !important;
  }
}

@media (min-width: 331px) and (max-width: 425px) {
  .special_offer_row {
    display: flex;
    flex-direction: column;
    height: 320px !important;
  }
  .special_offer_img {
    height: 139px !important;
  }
  /* .special_card {
    margin-right: 0px !important;
    height: 170px !important;
  } */

  .special_content {
    height: 110px !important;
  }

  .special_offername {
    font-size: medium !important;
  }

  .special_price {
    font-size: medium !important;
  }

  .special_service {
    font-size: small !important;
  }

  .special_des {
    font-size: small !important;
  }

  .countrywidth {
    max-width: 35% !important;
  }
}

/* special offer end */

/* First Image start */
.first_btn {
  font-size: x-large !important;
}

@media (min-width: 100px) and (max-width: 319px) {
  .first_btn {
    font-size: medium !important;
  }

  .reachasbtn {
    display: inline-block !important;
  }

  .mobile_card_view {
    margin: 25px;
  }
}

@media (min-width: 321px) and (max-width: 475px) {
  .first_btn {
    font-size: medium !important;
  }

  .reachasbtn {
    display: inline-block !important;
  }
  .mobile_card_view {
    margin: 25px;
  }
}

@media (min-width: 476px) and (max-width: 767px) {
  .first_btn {
    font-size: medium !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .first_img {
    height: 230px !important;
  }

  .first_btn {
    font-size: larger !important;
  }

  .countrywidth {
    max-width: "29%";
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .first_img {
    height: 300px !important;
  }
  .first_btn {
    font-size: x-large !important;
  }

  .countrywidth {
    max-width: "32%";
  }
}

@media (min-width: 1201px) and (max-width: 1399px) {
  .first_img {
    height: 300px !important;
  }

  .first_btn {
    font-size: x-large !important;
  }
  .call_btn {
    position: relative;
    margin-left: -23px !important;
    margin-top: 50px !important;
  }
  .skill_btn {
    text-wrap: nowrap !important;
  }
}

@media (min-width: 1400px) and (max-width: 2560px) {
  .first_img {
    height: 400px !important;
  }

  .first_btn {
    font-size: xx-large !important;
  }
}

.slick_icon .slick-prev:before,
.slick-next:before {
  color: #212529;
}

/* First Image End */

/* Styles for the active link */

.header_navbar a.active {
  color: #fff9f9;
  /* Change this to your desired active link color */
}
.header_navbar .nav_dpd_title a.active {
  color: #ffb33e;
  /* Change this to your desired active link color */
}

.v_modal .modal-content {
  border: 1px solid #ffb33e !important;
}

/* Data Grid Table Start*/
.data_grid .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.datagrid .css-11lq3yg-MuiGrid-root {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

/* Data Grid Table End*/

.speed_dial .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  background-color: #ffffff !important;
  border: 1px solid #ffbb33 !important;
  width: 47px !important;
  height: 47px !important;
  color: #000000 !important;
}

.speed_dial .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab:hover {
  background-color: #ffbb33 !important;
}
.speed_dial .css-r9uhzn {
  background-color: #ffffff !important;
  border: 1px solid #ffbb33 !important;
  width: 47px !important;
  height: 47px !important;
  color: #000000 !important;
  margin-right: -32px !important;
}

.speed_dial .css-r9uhzn:hover {
  background-color: #ffbb33 !important;
}

.speeddial .css-16jicls-MuiSpeedDial-root {
  right: -7px !important;
}

.call_help {
  margin-top: 65px !important;
  margin-right: -5px !important;
}

.datePicker_booking .react-date-picker__wrapper {
  border: 1px solid #ffbb33;
  height: 38px;
  border-radius: 5px;
}

.datePicker_booking .react-date-picker {
  display: flex;
}

.animated_text:hover {
  animation: none;
  opacity: 1;
}

.product_card {
  border-radius: "5px";
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: 0.2s;
}

.product_card:hover {
  cursor: pointer;
  box-shadow: 10px 30px 30px 10px rgba(0, 0, 0, 0.2);
}
.product_subImg {
  width: 80px;
  height: 60px !important;
  margin: 10px;
  border-radius: 5px;
  padding: 4px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
}
.product_subImg:hover {
  cursor: pointer;
  border: 2px solid red !important;
}

.product_btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  grid-gap: 1rem;
  gap: 1rem;
}
@media (min-width: 100px) and (max-width: 700px) {
  .product_btn {
    display: flex;
    margin-right: 0;
    grid-gap: 0;
    gap: 0;
    flex-direction: column;
  }
}

.limit-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bullet_color div::before {
  content: "\2022    ";
  color: "#C2C2C2";
  margin-right: 0.5em;
}
.consultant_tab_content {
  height: 85vh !important;
  overflow-y: scroll;
}

.data_grid .css-qvtrhg-MuiDataGrid-virtualScroller::-webkit-scrollbar {
  height: 10px;
}

/* Product Iamge Zoome */
.zoom_img {
  padding: 25px 50px;
}
.zoom_img .iiz__img {
  min-width: 100%;
  height: 300px !important;
  object-fit: fill;
}
.zoom_img .iiz {
  min-width: 100%;
}
@media (min-width: 100px) and (max-width: 500px) {
  .zoom_img {
    padding: 25px;
  }
  .zoom_img .iiz__img {
    height: 230px !important;
  }
}
@media (min-width: 501px) and (max-width: 800px) {
  .zoom_img {
    padding: 25px 50px;
  }
}
@media (min-width: 801px) and (max-width: 1100px) {
  .zoom_img {
    padding: 25px 70px;
  }
}
@media (min-width: 1101px) and (max-width: 1500px) {
  .zoom_img {
    padding: 25px 100px;
  }
}
@media (min-width: 1501px) and (max-width: 2000px) {
  .zoom_img {
    padding: 25px 130px;
  }
}
@media (min-width: 2001px) {
  .zoom_img {
    padding: 25px 200px;
  }
}

/* our product on home  */
.ourproduct_img {
  width: 140px;
  height: 120px;
  object-fit: cover;
  border: 1px solid #ffb33c;
  border-radius: 10px;
}
.ourproduct_img:hover {
  cursor: pointer;
  box-shadow: 0px 0px 0px 1px rgb(190, 189, 189);
}

.map_location {
  height: 150px;
  width: 350px;
}

@media (min-width: 100px) and (max-width: 400px) {
  .ourproduct_img {
    width: 105px;
  }
  .map_location {
    height: 150px;
    width: 278px;
  }
}
@media (min-width: 700px) and (max-width: 1200px) {
  .ourproduct_img {
    width: 120px;
  }
  .map_location {
    height: 150px;
    width: 194px;
  }
  .call_btn {
    position: relative;
    margin-left: -81px !important;
    margin-top: 69px !important;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .ourproduct_img {
    width: 120px;
  }
  .call_btn {
    position: relative;
    margin-left: -23px !important;
    margin-top: 83px !important;
  }
  .skill_btn {
    text-wrap: nowrap !important;
  }
}
@media (min-width: 1400px) and (max-width: 2000px) {
  .ourproduct_img {
    width: 150px;
  }
}

.tam_font a {
  font-size: 14px !important;
}
.tam_font .building_navlink {
  font-size: 14px !important;
}

@media (min-width: 100px) and (max-width: 991px) {
  .header_navbar a.active {
    color: #ffb33c !important;
  }
  .nav_linkhead:hover {
    color: #ffb33c;
  }
  .logout_btn {
    margin-top: 5px;
  }
}

@media (min-width: 100px) and (max-width: 350px) {
  .tam_font a {
    font-size: 12px !important;
  }
  .tam_font .building_navlink {
    font-size: 10px !important;
  }
  .call_btn {
    position: relative;
    margin-left: -81px !important;
    margin-top: 69px !important;
  }
  .selected_service_radio {
    flex-direction: column;
  }
  .review_btn {
    display: flex !important;
    flex-direction: row;
  }
}
@media (min-width: 351px) and (max-width: 767px) {
  .tam_font a {
    font-size: 12px !important;
  }
  .tam_font .building_navlink {
    font-size: 10px !important;
  }
  .logout_btn {
    margin-top: 5px;
  }
  .call_btn {
    position: relative;
    margin-left: -81px !important;
    margin-top: 69px !important;
  }
  .selected_service_radio {
    flex-direction: column;
  }
  .review_btn {
    display: flex !important;
    flex-direction: row;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .tam_font a {
    font-size: 10px !important;
  }
}

.pay_date_picker {
  border: 2px solid #ffb33e;
  border-radius: 5px;
  padding-left: 5px;
}
.pay_date_picker .react-datepicker-ignore-onclickoutside {
  border: 2px solid #ffb33e;
}
.all_border_pay .MuiPaper-elevation1 {
  border: 2px solid #ffb33e !important;
}
.ul li {
  list-style: circle !important;
}

/* Social pages */
.social_footer {
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  margin-top: 10px;
  padding: 5px 0px;
}
.social_consultant {
  height: 70vh;
  overflow-y: scroll;
}
.social_con_header {
  display: flex;
  align-items: center;
}
.social_con_content {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
}
.otp_input {
  display: flex;
  justify-content: center;
}
.navTab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #ffb33e !important;
}
.navTab .nav-link {
  color: #000000 !important;
}
.create_slot_datepic:focus {
  border: 2px solid #ffb33e !important;
  border-radius: 2px;
  outline: none;
}
.use_now_card:hover {
  transform: translateY(-10px);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
}

.type_of_service {
  background-color: #ffc33b;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.recharge_btn {
  display: flex;
  justify-content: flex-end;
}

.card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-hover:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.selected_service_radio {
  display: flex;
  justify-content: space-evenly;
}

.radio-item {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
}

.img_hov {
  display: inline-block;
  perspective: 1000px;
}

.image-3d {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.image-3d:hover {
  transform: rotateY(20deg) rotateX(10deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}
@media (min-width: 100px) and (max-width: 767px) {
  .our_service_mob {
    display: block;
  }
  .our_service_tab {
    display: none;
  }
  .our_service_desk {
    display: none;
  }
  .admin_ado_history {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  /* .talknow_mobile{
  display: flex;
  justify-content: space-around;
} */
}
@media (min-width: 768px) and (max-width: 991px) {
  .our_service_mob {
    display: none;
  }
  .our_service_tab {
    display: block;
  }
  .our_service_tab {
    display: none;
  }
  .our_service_desk {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .our_service_mob {
    display: none;
  }
  .our_service_tab {
    display: block;
  }
  .our_service_desk {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 2560px) {
  .our_service_mob {
    display: none;
  }
  .our_service_tab {
    display: none;
  }
  .our_service_desk {
    display: block;
  }
}

.blink {
  background-color: #ffb33c;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-flex;
  grid-gap: 2px;
  gap: 2px;
  padding: 3px;
  border-radius: 4px;
}

.TalkNow .navTab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  background-color: #e1dbcf !important;
}

.admindate_history {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.admin_ado_history {
  display: flex;
  justify-content: space-evenly;
}

.consul_chat_his .react-date-picker__wrapper {
  border-radius: 5px;
  border: thin solid #faad14;
  height: 38px;
}

.enter_review_con {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

/*Chat Screen*/
.chat_input_feild {
  padding: 10px;
  border: 1px solid #ffb33e;
  border-radius: 5px;
  margin-right: 10px;
}

.chat_input_feild:focus {
  outline: none;
  box-shadow: 0 0 0 0.1rem #ffb33e !important;
}
.chat_send_btn {
  padding: 10px 20px !important;
}
.chat_text_message {
  max-width: 70%;
}

.chat_view_main {
  padding: 20px;
  height: 100%;
  overflow: scroll;
}

.chat_view_back {
  padding: 0px 20px;
}

@media (min-width: 100px) and (max-width: 319px) {
  .chat_input_feild {
    width: 160px;
    padding: 5px;
  }
  .chat_send_btn {
    padding: 5px !important;
  }
}

@media (min-width: 320px) and (max-width: 355px) {
  .chat_input_feild {
    width: 183px;
    padding: 5px;
  }
  .chat_send_btn {
    padding: 5px 7px !important;
  }
}
@media (min-width: 356px) and (max-width: 377px) {
  .chat_input_feild {
    width: 210px;
    padding: 7px;
  }
  .chat_send_btn {
    padding: 7px 10px !important;
  }
}
@media screen and (max-width: 600px) {
  .chat_text_message {
    max-width: 90%;
  }
  .chat_view_main {
    padding: 10px !important;
  }
  .chat_view_back {
    padding: 0px 5px;
  }
}

#video-call-div {
  position: absolute;
  top: 7%;
  left: 0;
  width: 100%;
  height: 90%;
  /* display: none;  for sender.js */
  display: none; /*  for call.js block*/
}

#local-video {
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px;
  border-radius: 16px;
  max-width: 20%;
  max-height: 20%;
  background: #ffffff;
}

#remote-video {
  background: #000000;
  width: 100%;
  height: 100%;
}

.video-action-div {
  position: absolute;
  left: 20%;
  bottom: 32px;
  width: 76%;
  display: flex;
  justify-content: center;
}

button {
  cursor: pointer;
  /* border-radius: 50; */
  margin: 5px;
}

.main {
  display: flex;
  align-items: flex-end;
  grid-gap: 3rem;
  gap: 3rem;
}
.main__right {
  display: none;
  flex-direction: column;
  background-color: #242324;
  border-left: 1px solid #3d3d42;
}
.main__right.active {
  display: flex;
}
.main__videos {
  flex-grow: 1;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main__controls {
  display: flex;
  background-color: #1c1e20;
  color: #d2d2d2;
  padding: 5px;
  justify-content: space-between;
}
.main__header {
  color: #f5f5f5;
  text-align: center;
  padding: 20px;
  border-bottom: 2px solid #3d3d42;
}
.main__message_container {
  display: flex;
  justify-content: center;
}
.main__message_container textarea {
  background-color: transparent;
  border: none;
  color: #000000;
  font-size: 18px;
  outline: none;
  border-radius: 20px;
}

.sendMsgBtn {
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: #fff;
  background-color: #008000;
  border-radius: 20px;
  padding-left: 5px;
  margin-left: 5px;
}

#audio-call-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

#local-audio {
  position: absolute;
}

#remote-audio {
  background: #000000;
  width: 100%;
  height: 100%;
}

.call-action-div {
  position: absolute;
  display: flex;
  left: 45%;
  bottom: 30%;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
}

#chat-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  display: none;
}
#chat-animate-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  background-color: #4b4e50;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.connecting_div {
  color: #fff;
  margin-top: 50px;
}

#local-chat {
  position: absolute;
}

#remote-chat {
  background: #ffffff;
  width: 100%;
  height: 100%;
}

.chat-action-div {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  bottom: 5px;
  width: 100%;
  padding: 10px;
}

.send_btn {
  border-color: #ffb33e !important;
  background-color: #ffffff !important;
  color: black !important;
  cursor: pointer;
  margin: 5px;
}

.send_btn:hover {
  font-weight: bold !important;
}

.chatBox {
  margin: 10px;
  margin-bottom: 15px;
  padding: 10px;
  background: rgb(255, 216, 43);
  width: 100%;
  height: 73vh;
  border: 1px solid #a7a7a7;
  overflow: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.videochatBox {
  background: rgb(255, 216, 43);
  padding: 10px;
  width: 100%;
  height: 65vh;
  border: 1px solid #a7a7a7;
  overflow: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#chatarea {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: auto;
}
.message {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  max-width: 60%;
}

.my-message {
  background-color: #f0f0f0;
  align-self: flex-end; /* Align "Me" messages to the right */
  text-align: left;
}

.remote-message {
  background-color: #d1e7dd;
  align-self: flex-start; /* Align "Remote" messages to the left */
}

@media (min-width: 100px) and (max-width: 319px) {
  #video-call-div {
    top: 20%;
    height: 78%;
  }
  .video-action-div {
    left: 6%;
    flex-direction: column-reverse;
  }
  .call-action-div {
    left: 35%;
  }
  .chat-action-div {
    flex-direction: column-reverse;
    bottom: 1px;
  }
  .message {
    max-width: 90%;
  }
}
@media (min-width: 320px) and (max-width: 374px) {
  #video-call-div {
    top: 20%;
    height: 78%;
  }
  .video-action-div {
    left: 6%;
    flex-direction: column-reverse;
  }
  .chat-action-div {
    flex-direction: column-reverse;
    bottom: 1px;
  }
  .message {
    max-width: 90%;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  #video-call-div {
    top: 20%;
    height: 78%;
  }
  .video-action-div {
    left: 10%;
    flex-direction: column-reverse;
  }
  .call-action-div {
    left: 38%;
  }
  .chat-action-div {
    flex-direction: column-reverse;
    bottom: 1px;
  }
  .message {
    max-width: 90%;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  #video-call-div {
    top: 12%;
    height: 86%;
  }
  .video-action-div {
    left: 15%;
  }
  .call-action-div {
    left: 43%;
  }
  .chat-action-div {
    bottom: 1px;
  }
  .message {
    max-width: 85%;
  }
}

@media (max-width: 640px) {
  .main_controls_button span {
    display: none;
  }
  .main__right {
    position: fixed;
    width: 100%;
    opacity: 0.8;
    margin-bottom: 46px;
    left: 0%;
    padding: 2%;
  }
  .videochatBox {
    height: 50vh;
  }
  .chatBox {
    height: 75vh;
  }
}

/* body {
    background-color: #eee
} */

.card {
    border: none;
    border-radius: 10px;
    background-color: #eee
}

.c-details span {
    font-weight: 300;
    font-size: 13px
}

.icon {
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 39px
}

.badge span {
    background-color: #fffbec;
    width: 60px;
    height: 25px;
    padding-bottom: 3px;
    border-radius: 5px;
    display: flex;
    color: #fed85d;
    justify-content: center;
    align-items: center
}

.progress {
    height: 10px;
    border-radius: 10px
}

.progress div {
    background-color: red
}

.text1 {
    font-size: 14px;
    font-weight: 600
}

.text2 {
    color: #a5aec0
}
